@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Override the background color of an input when is autofilled by the broswers */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--color-surface-0) inset !important;
    -webkit-text-fill-color: var(--color-text) !important;
}

/* override the default scrollbar style on chromium based browsers to match mantine's */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-surface-2) !important;
    border-radius: var(--font-size-xs);
}

::-webkit-scrollbar-corner {
    background-color: var(--color-surface-0);
}

/* override all the font-family, box-sizing and scrollbar width for the whole page */
* {
    font-family: 'Inter', sans-serif !important;
    box-sizing: border-box !important;
}

/*
 default variable that we would like to use across
 all the stylings on the page, trying to achive the most
 consistent system possible

    tips:
    - css vars must be prefixed with --

    - when manually setting a padding or margin, try to
    use a multiple of 8, this will help to keep the
    consistency across the page, example 8px, 24px, 32px, etc

    - when setting a font-size, try to use the following
    values: 12px, 14px, 16px, 20px, 24px, 32px, 48px, 64px

    - when adding a color or testing a color combination, remember
    to check for the contrast ratio, this will help to keep the
    text readable and accessible, multiple tools are available online
*/
:root {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --default-animation-duration: 0.2s;
    --default-border: 1px solid;
    --default-border-width: 1px;
    --default-border-radius: 5px;
    --scale: 1;

    --color-primary: #456a4b;
    --color-primary-75: #456a4bbf;
    --color-primary-50: #456a4b80;
    --color-primary-25: #456a4b40;
    --color-primary-hover: #638f69;

    --color-error: #e03131;
    --color-error-hover: #ff6f6f;
    --color-success: #4caf50;
    --color-success-hover: #6fcf6f;

    /* override the color used to fill components with the primary scheme, eg: buttons, selected month picker input */
    --mantine-primary-color-filled: var(--color-primary) !important;
    /* override the color used to fill hovered components with the primary scheme, eg: buttons, selected month picker input */
    --mantine-primary-color-filled-hover: var(--color-primary-hover) !important;
    /* override the checkbox selected color*/
    --mantine-color-blue-filled: var(--color-primary) !important;
    /* override the color used on the mantine action icons on hover eg: CommodityTable/UserTable when not open*/
    --mantine-color-blue-9: var(--color-primary-hover) !important;


    --font-size-xxs: calc(0.625rem * var(--scale));
    --font-size-xs: calc(0.75rem * var(--scale));
    --font-size-sm: calc(0.875rem * var(--scale));
    --font-size-md: calc(1rem * var(--scale));
    --font-size-lg: calc(1.125rem * var(--scale));
    --font-size-xl: calc(1.25rem * var(--scale));
    --font-size-xxl: calc(1.75rem * var(--scale));

    --chart-1: 230 90% 40%;
    --chart-2: 230 70% 60%;
    --chart-3: 200 90% 60%;
    --chart-4: 180 90% 66%;
    --chart-5: 159 62% 56%;
    --chart-6: 140 80% 72%;
    --chart-7: 110 71% 68%;
    --chart-8: 90 62% 59%;
    --chart-9: 70 53% 50%;
    --chart-10: 50 71% 44%;
    --chart-11: 40 100% 41%;
    --chart-12: 35 100% 44%;
    --chart-13: 30 100% 47%;
    --chart-14: 20 75% 53%;
    --chart-15: 0 50% 59%;
    --chart-16: 330 50% 53%;
    --chart-17: 300 60% 47%;
    --chart-18: 270 87% 56%;
    --chart-19: 250 71% 59%;
    --chart-20: 230 55% 62%;
    --chart-21: 200 60% 50%;
    --chart-22: 180 70% 41%;
    --chart-23: 170 100% 41%;
    --chart-24: 160 100% 53%;
    --chart-25: 140 71% 56%;
    --chart-26: 120 62% 56%;
    --chart-27: 100 62% 47%;
    --chart-28: 80 87% 38%;
    --chart-29: 50 100% 38%;
    --chart-30: 40 100% 44%;
    --radius: 0.5rem;
}

/*
    define the color scheme depending on the selected theme,
    this is handled on App.js with the next two lines

    const {colorScheme} = useMantineColorScheme();
    document.body.setAttribute('data-theme', colorScheme);

    the idea is to use the same names for the different colors hierarchies,
    so we can easily switch between themes without changing the
    class names
*/
[data-theme = "light"] {

    --color-surface-0: #FCFBFB;
    --color-surface-070: rgba(252, 251, 251, 0.7);
    --color-surface-1: #F5F5F5;
    --color-surface-2: #D0D3E5;
    --color-surface-3: #212529;
    --color-surface-4: #000000;

    --color-secondary: #EBEBEB;
    --color-tertiary: #212529;
    --color-text: #212529;

    /* colors used to categorize each quotation table's row depending on the quotation status */
    --color-sent: #dcd900;
    --color-updated: #21d000;
    --color-quoted: #21d000;
    --color-rejected: #b91010;

    /* override the mantine colors that are used to set the
    default background or border colors to the mantine components
    doing this we avoid to change every single component manually */

    --mantine-color-gray-3: var(--color-surface-2); /* mantine light component default border color */
    --mantine-color-white: var(--color-surface-0); /* mantine light component default background color */
    --mantine-color-blue-7: var(--color-primary); /* mantine light component default primary color */
    --mantine-color-text: var(--color-text); /* mantine light component default text color */
    --mantine-color-body: var(--color-surface-1); /* mantine light component default body color */
    --mantine-color-blue-filled-hover: var(--color-primary-hover) !important; /* mantine light component default primary color hover */
    --mrt-base-background-color: var(--color-surface-1); /* mrt header background color */
    --mrt-row-hover-background-color: var(--color-surface-1); /* mrt row hover background color */

    --bs-body-bg: var(--color-surface-0) !important; /* bootstrap body background color */
    --bs-border-color-translucent: var(--color-surface-2) !important; /* bootstrap border color */
    --bs-border-color: var(--color-surface-2) !important; /* bootstrap border color */
}

[data-theme = "dark"] {

    --color-surface-0: #1E1F23;
    --color-surface-070: #1E1F23B3;
    --color-surface-1: #303136;
    --color-surface-2: #43444C;
    --color-surface-3: #C3C3C3;
    --color-surface-4: #fcfbfb;

    --color-secondary: #1E1F23;
    --color-tertiary: #BABEC2;
    --color-text: #C3C3C3;

    /* colors used to categorize each quotation table's row depending on the quotation status */
    --color-sent: #edf33d;
    --color-updated: #339419;
    --color-quoted: #339419;
    --color-rejected: #d91b1b;

    /* override the mantine colors that are used to set the
    default background or border colors to the mantine components
    doing this we avoid to change every single component manually */

    --mantine-color-dark-4: var(--color-surface-2); /* mantine dark component default border color */
    --mantine-color-dark-6: var(--color-surface-0); /* mantine dark component default background color */
    --mantine-color-blue-9: var(--color-primary); /* mantine dark component default primary color */
    --mantine-color-text: var(--color-text); /* mantine dark component default text color */
    --mantine-color-body: var(--color-surface-1); /* mantine dark modal component default body color */

    --mrt-base-background-color: var(--color-surface-1); /* mrt header background color */
    --mrt-row-hover-background-color: var(--color-surface-1); /* mrt row hover background color */

    --mantine-color-dark-5: var(--color-surface-0) !important; /* mantine table row hover background color, used at ChargesTable */


    --bs-body-bg: var(--color-surface-0) !important; /* bootstrap body background color */
    --bs-border-color-translucent: var(--color-surface-2) !important; /* bootstrap border color */
    --bs-border-color: var(--color-surface-2) !important; /* bootstrap border color */
}

/* set the label colors of all the components */
label {
    color: var(--color-primary);
}

/* setting this, each modal has an overlay which will darken everything but the modal */
.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

/* reduce the space between the checkbox icon and the label */
.mantine-Checkbox-label {
    padding-inline-start: 4px !important;
}

/* override the asterisk color on required inputs to use the primary color */
.mantine-InputWrapper-required {
    color: var(--color-primary) !important;
}

/* if the selected theme is dark, invert the color of the modal bootstrap close button*/
[data-theme = "dark"] .btn-close {
    filter: invert(1);
}

/* align the close button on bootstrap modals to respect the margin */
.modal-header .btn-close {
    margin: 0;
}

/* allows the bootstrap modal header to grow if the text wraps into more than one line */
.modal-header {
    height: auto !important;
    align-items: start !important;
    padding: 12px;
}

/* reduce the padding of the bootstrap modal body */
.modal-body {
    padding: 8px;
}

/* reduce the padding of the bootstrap modal footer */
.modal-footer {
    padding: 8px 12px;
}

/* reduce the line-height of the bootstrap modal, doing this we get a smaller header
 if we reduce this number to much, it will overlap the title text when a title is long
 enough to be wrapped */
.modal-header > h2 {
    line-height: 24px;
}

.modal-header > div > h2 {
    line-height: 24px;
}

/* reduce the z-index on the navbar to allow showing the MRT tooltips without cutting them */
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5 !important;
}

/*-------------------------- variants declaration start --------------------------*/

[util-variant = "inherit-fh"] > * {
    height: 100% !important;
}

/* data attribute to override the position, you can access to this on a component using
 pos-variant={value} */
[pos-variant = "absolute"] {
    position: absolute !important;
}

[pos-variant = "relative"] {
    position: relative !important;
}

/* data attribute to override the color, you can access to this on a component using
 c-variant={value} */
[c-variant = "primary"] {
    color: var(--color-primary) !important;
}

[c-variant = "secondary"] {
    color: var(--color-secondary) !important;
}

[c-variant = "tertiary"] {
    color: var(--color-tertiary) !important;
}

[c-variant = "success"] {
    color: var(--color-success) !important;
}

[c-variant = "error"] {
    color: var(--color-error) !important;
}

[c-variant = "approved"] {
    color: var(--color-updated) !important;
}

[c-variant = "sent"] {
    color: var(--color-sent) !important;
}

[c-variant = "rejected"] {
    color: var(--color-rejected) !important;
}

[c-variant = "text"] {
    color: var(--color-text) !important;
}

[c-variant = "surface-0"] {
    color: var(--color-surface-0) !important;
}

[c-variant = "surface-1"] {
    color: var(--color-surface-1) !important;
}

[c-variant = "surface-2"] {
    color: var(--color-surface-2) !important;
}

[c-variant = "surface-3"] {
    color: var(--color-surface-3) !important;
}

[c-variant = "surface-4"] {
    color: var(--color-surface-4) !important;
}

[c-variant = "chart-1"] {
    color: hsl(var(--chart-1)) !important;
}

[c-variant = "chart-2"] {
    color: hsl(var(--chart-2)) !important;
}

[c-variant = "chart-3"] {
    color: hsl(var(--chart-3)) !important;
}

[c-variant = "chart-4"] {
    color: hsl(var(--chart-4)) !important;
}

[c-variant = "chart-5"] {
    color: hsl(var(--chart-5)) !important;
}

[c-variant = "chart-6"] {
    color: hsl(var(--chart-6)) !important;
}

[c-variant = "chart-7"] {
    color: hsl(var(--chart-7)) !important;
}

[c-variant = "chart-8"] {
    color: hsl(var(--chart-8)) !important;
}

[c-variant = "chart-9"] {
    color: hsl(var(--chart-9)) !important;
}

[c-variant = "chart-10"] {
    color: hsl(var(--chart-10)) !important;
}

/* data attribute to override the font size, you can access to this on a component using
 fs-variant={value} */
[fs-variant = "xxs"] {
    font-size: var(--font-size-xxs) !important;
}

[fs-variant = "xs"] {
    font-size: var(--font-size-xs) !important;
}

[fs-variant = "sm"] {
    font-size: var(--font-size-sm) !important;
}

[fs-variant = "md"] {
    font-size: var(--font-size-md) !important;
}

[fs-variant = "lg"] {
    font-size: var(--font-size-lg) !important;
}

[fs-variant = "xl"] {
    font-size: var(--font-size-xl) !important;
}

[fs-variant = "xxl"] {
    font-size: var(--font-size-xxl) !important;
}

/* data attribute to override the padding, you can access to this on a component using
 p-variant={value} */
[p-variant = "0"] {
    padding: 0 !important;
}

[p-variant = "4"] {
    padding: 4px !important;
}

[p-variant = "8"] {
    padding: 8px !important;
}

/* data attribute to override the border-color, you can access to this on a component using
 bc-variant={value} */
[bc-variant = "default"] {
    border-color: var(--color-surface-2) !important;
}

[bc-variant = "primary"] {
    border-color: var(--color-primary) !important;
}

[brdr-variant = "default"] {
    border: var(--default-border) var(--color-surface-2) !important;
}

[brdr-variant = "default-bottom"] {
    border-bottom: var(--default-border) var(--color-surface-2) !important;
}

[brdr-variant = "default-top"] {
    border-top: var(--default-border) var(--color-surface-2) !important;
}

[brdr-variant = "default-left"] {
    border-left: var(--default-border) var(--color-surface-2) !important;
}

[brdr-variant = "default-right"] {
    border-right: var(--default-border) var(--color-surface-2) !important;
}

[brdr-variant = "default-sides"] {
    border-left: var(--default-border) var(--color-surface-2) !important;
    border-right: var(--default-border) var(--color-surface-2) !important;
}

[brdr-variant = "default-rounded"] {
    border: var(--default-border) var(--color-surface-2) !important;
    border-radius: var(--default-border-radius) !important;
}

[brdr-variant = "none-bottom"] {
    border-bottom: none !important;
}

[brdr-variant = "none"] {
    border: none !important;
}

[brdr-variant = "none-rounded"] {
    border: none !important;
    border-radius: var(--default-border-radius) !important;
}

/* data attribute to override the text-wrap, you can access to this on a component
 using tw-variant={value} */
[tw-variant = "no-wrap"] {
    text-wrap: nowrap;
}

/* data attribute to override the background color, you can access to this on a component
 using bg-variant={value} */
[bg-variant = "primary"] {
    background-color: var(--color-primary) !important;
}

[bg-variant = "secondary"] {
    background-color: var(--color-secondary) !important;
}

[bg-variant = "tertiary"] {
    background-color: var(--color-tertiary) !important;
}

[bg-variant = "surface-0"] {
    background-color: var(--color-surface-0) !important;
}

[bg-variant = "surface-070"] {
    background-color: var(--color-surface-070) !important;
}

[bg-variant = "surface-1"] {
    background-color: var(--color-surface-1) !important;
}

[bg-variant = "surface-2"] {
    background-color: var(--color-surface-2) !important;
}

[bg-variant = "surface-3"] {
    background-color: var(--color-surface-3) !important;
}

[bg-variant = "surface-4"] {
    background-color: var(--color-surface-4) !important;
}

[bg-variant = "transparent"] {
    background-color: transparent !important;
}

[bg-variant = "linear-0-1"] {
    background: linear-gradient(var(--color-surface-0), var(--color-surface-1));
}

[bg-variant = "linear-1-2"] {
    background: linear-gradient(var(--color-surface-1), var(--color-surface-2));
}

/* data attribute to override the background color on hover, you can access to this on a component
 using hbg-variant={value} */
[hbg-variant = "primary"]:hover {
    background-color: var(--color-primary-hover) !important;
}

[hbg-variant = "surface-0"]:hover {
    background-color: var(--color-surface-0) !important;
}

[hbg-variant = "surface-1"]:hover {
    background-color: var(--color-surface-1) !important;
}

/* data attribute to add a blinking effect to a component, ideal for catching the user's attention to
components that appear under certain condition, you can access to this on a component using blink-variant={value} */
@keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

[hbg-variant = "surface-2"]:hover {
    background-color: var(--color-surface-2) !important;
}

[blink-variant = "true"] {
    border-style: solid !important;
    border-color: var(--color-primary) !important;
    border-width: 1px !important;
    border-radius: 5px;
    animation: blink 1s 1;
}

[animation-helper-variant = "scale-hover"] {
    transition: all 0.2s;
}

[animation-helper-variant = "scale-hover"]:hover {
    transform: scale(1.02);
}


/* data attribute to add boxShadow to a component, you can access to this on a component
 using boxs-variant={value} */
[boxs-variant = "surface-2"] {
    box-shadow: 0 0 10px var(--color-surface-2) !important;
}

/* data attribute to override the margin top on nested modals, you can access to this on a component
 using nested={value} */
[nested = "0"] {
    margin-top: 3% !important;
}

[nested = "1"] {
    margin-top: 5% !important;
}

[nested = "2"] {
    margin-top: 7% !important;
}

[nested = "3"] {
    margin-top: 9% !important;
}

[nested = "4"] {
    margin-top: 11% !important;
}

/* data attribute to override the overflow, you can access to this on a component
 using ovf-variant={value} */
[ovf-variant = "hidden"] {
    overflow: hidden !important;
}

[ovf-variant = "scroll"] {
    overflow: scroll !important;
}

[ovf-variant = "visible"] {
    overflow: visible !important;
}

[ovf-variant = "auto"] {
    overflow: auto !important;
}

[ovf-variant = "scrollx"] {
    overflow-x: auto !important;
}

[ovf-variant = "scrolly"] {
    overflow-y: auto !important;
}

/* data attribute to override the height, you can access to this on a component
 using h-variant={value} */
[h-variant = "xs"] {
    height: var(--input-height-xs) !important;
}

/* override the default colors of the bootstrap form checker component */
.form-check-input:checked {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

/* defines the style of the base html table across the whole aplication */

.customTableContainer {
    border: var(--default-border) var(--color-surface-2);
    border-radius: var(--default-border-radius);
    overflow: hidden;
}

.customTableContainer > table {
    width: 100%;
    background-color: var(--color-surface-1);
}

.customTableContainer > table > thead {
    background-color: var(--color-surface-1);
    color: var(--color-primary);
    position: sticky;
    top: 0;
    z-index: 1;
}

.customTableContainer > table > thead:hover > tr {
    background-color: var(--color-surface-0);
}

.customTableContainer > table > thead > tr > th {
    background-color: var(--color-surface-0);
    user-select: none;
    text-align: center;
    font-size: var(--font-size-xs);
}

.customTableContainer > table > tbody > tr {
    border-style: none;
    border-top-style: solid;
    border-color: var(--color-surface-2);
    border-width: var(--default-border-width);
}

.customTableContainer > table > tbody > tr > td {
    background-color: var(--color-surface-0);
    text-align: center;
    color: var(--color-text);
}

/* defines the style of the base html table across the whole aplication, used mainly for costSell components */

.customTableContainerExtraPadding {
    border-radius: var(--default-border-radius);
    overflow: hidden;
    border: var(--default-border) var(--color-surface-2);
    border-bottom-style: none;
}

.noBorder {
    border: none;
}

.customTableContainerExtraPadding > table {
    width: 100%;
    background-color: var(--color-surface-1);
}

.customTableContainerExtraPadding > table > thead {
    background-color: var(--color-surface-1);
    color: var(--color-primary);
    position: sticky;
    top: 0;
    z-index: 1;
}

.customTableContainerExtraPadding > table > thead:hover > tr {
    background-color: var(--color-surface-1);
}

.customTableContainerExtraPadding.surface-1 > table > thead:hover > tr {
    background-color: var(--color-surface-1);
}

.customTableContainerExtraPadding > table > thead > tr > th {
    background-color: var(--color-surface-1);
    padding: 8px;
    user-select: none;
    text-align: center;
    font-size: var(--font-size-xs);
}

.customTableContainerExtraPadding.surface-1 > table > thead > tr > th {
    background-color: var(--color-surface-1);
}

.customTableContainerExtraPadding > table > tbody > tr {
    border-style: none;
    border-bottom-style: solid;
    border-width: var(--default-border-width);
    border-color: var(--color-surface-2);
}

.customTableContainerExtraPadding > table > tbody > tr > td {
    background-color: var(--color-surface-1);
    text-align: center;
    color: var(--color-text);
    padding: 8px;
}

.customTableContainerExtraPadding.surface-1 > table > tbody > tr > td {
    background-color: var(--color-surface-1);
}

/* custom containers */

.container-6 {
    flex-basis: 6%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.container-12 {
    flex-basis: 12%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.container-15 {
    flex-basis: 15%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.container-16 {
    flex-basis: 16%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.container-20 {
    flex-basis: 20%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.container-25 {
    flex-basis: 25%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.container-33 {
    flex-basis: 33%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.container-35 {
    flex-basis: 35%;
    padding: 0 8px;
    margin-bottom: 8px;
}

.custom-modal {
    display: block;
    min-width: 95% !important;
}

.custom-modal-975 {
    width: auto !important;
    min-width: 40% !important;
    max-width: 97.5%;
    max-height: 90%;
}

/* override the font size and padding between the circle icon reference */
.recharts-legend-wrapper > * > * > * {
    font-size: var(--font-size-xs);
    padding: 0 !important;
}

/* override the padding of each legend entry */
.recharts-legend-wrapper > * > * {
    padding: 2px !important;
}

/* override the legend container's display */
.recharts-legend-wrapper > * {
    display: flex;
    justify-content: start;
    gap: 4px;
}

/* overrides the gap between the legend text and the legend icon*/
.mantine-ChartLegend-legendItem {
    gap: 2px !important
}

/* TODO from this point to the end of the document, the css is unrevised, the idea is to end up deleting all the unused classes */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

tr.th-row-cargonet:hover {
    background-color: var(--color-secondary) !important;
    user-select: none;
}

.mantine-Table-tr.color2:hover {
    background-color: #fd9737;
    color: inherit;
    user-select: none;
}

tr:hover th {
    background-color: transparent !important;
}

.labelCotizacion {
    width: 100%;
    color: var(--color-primary);
    font-weight: bold;
    margin-left: 2px;
}

.inputCotizacion {
    width: 100%;
    border-radius: 6px;
}

.back-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.finalize-select {
    max-width: 140px;
    width: 100%;
    height: 18px;
}

.not-editable {
    background-color: rgb(210, 209, 209);
    border-radius: 6px;
    cursor: default;
    width: 100%;
}

.btn.btn-success.cargonet.header {
    margin-right: 15px;
    margin-top: 5px;
    height: 30px;
    display: flex;
    align-items: center;
}

.tabla-cargonet {
    font-size: 13px;
    margin: 5px;
    border-radius: 16px;
    padding: 0 20px 20px 20px;
    display: flex;
    flex: column;
    overflow-y: scroll;
    max-height: 680px;
}

.tabla-cargonet table th {
    padding-top: 20px;
}

.adaptable-modal {
    min-width: 38.5rem !important;
    max-width: fit-content;
}

.mantine-Button-root.danger.table {
    background-color: red;
    min-height: 22.5px;
    max-height: 22.5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5px;
    max-width: 30.95px;
}

.mantine-Button-root.success.table {
    background-color: green;
    min-height: 22.5px;
    max-height: 22.5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5px;
    max-width: 30.95px;
}

.quill {
    border-radius: 6px;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.ql-container.ql-snow {
    background-color: #fff;
    height: 265px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.bullet-point {
    position: absolute;
    top: -3px;
    right: 0;
    transform: translate(50%, -50%);
    font-size: 70px;
    color: green;
}

.max-index-dropdown {
    z-index: 1000;
}

.row-action-button {
    color: var(--color-primary);
}

.row-action-button:hover {
    color: var(--color-secondary);
}

.mantine-Select-dropdown {
    z-index: 2000 !important;
}

.mantine-Badge-root {
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    margin-right: 5px;
}

.mantine-Pill-root {
    color: white !important;
    background-color: var(--color-primary) !important;
}

.mantine-Button-root.secondary {
    background-color: var(--color-secondary) !important;
    color: var(--color-text) !important;
}

.mantine-Button-root.secondary:hover {
    background-color: #565f68 !important;
}

.mantine-Button-root.tertiary {
    background-color: var(--color-tertiary) !important;
}

.mantine-Button-root.tertiary:hover {
    background-color: #565f68 !important;
}

.mantine-Button-root.primary.header {
    margin-right: 15px;
    margin-top: 5px;
    height: 30px;
    display: flex;
    align-items: center;
}

.mantine-Chip-label {
    background-color: var(--color-primary) !important;
    color: white !important;
    width: 100%;
}

.mantine-ActionIcon-root.primary {
    background-color: transparent;
    color: var(--color-primary) !important;
}

.mantine-ActionIcon-root.primary:hover {
    color: #efa25f !important;
}

.mantine-MultiSelect-dropdown {
    z-index: 2000 !important;
}

.custom-backgroundColor-secondary {
    background-color: #3b3f43 !important;
}

.color-black {
    color: black !important;
}

.default-table-header {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 750;
    color: var(--color-primary) !important;
    background-color: var(--color-secondary) !important;
}

.default-table-cell-label {
    text-align: center !important;
    font-size: 0.75rem;
    color: var(--color-primary) !important;
}

.default-table-cell {
    text-align: center !important;
}

.border-bottom-label {
    border-bottom: 1px solid var(--color-primary);
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.modal-header {
    height: 2.6875rem;
}

.mantine-Modal-title {
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-align: center !important;
    width: 100% !important;
}

.custom-scroll::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    scroll-margin-top: 100px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 4px;
    scroll-margin-top: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-primary);
}

.custom-scroll::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mrt-expand-button {
    width: 1.125rem !important;
    height: 1.125rem !important;
}

.modal-body::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 4px;
    scroll-margin-top: 10px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
    background-color: grey;
}

.modal-body::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
}

.font-table-xs {
    font-size: var(--mantine-font-size-xs);
}