[bg-variant = "primary"] {
    transition: all var(--default-animation-duration) !important;
    background-color: var(--color-primary) !important;
    color: var(--color-surface-0) !important;
    border: var(--color-surface-2) var(--default-border) !important;
}

[bg-variant = "primary"]:hover {
    background-color: var(--color-primary-hover) !important;
}

[bg-variant = "secondary"] {
    transition: all var(--default-animation-duration) !important;
    background-color: var(--color-surface-4) !important;
    color: var(--color-surface-0) !important;
    border: var(--color-surface-2) var(--default-border) !important;
}

[bg-variant = "secondary"]:hover {
    background-color: var(--color-surface-3) !important;
}

[bg-variant = "tertiary"] {
    transition: all var(--default-animation-duration) !important;
    background-color: var(--color-surface-1) !important;
    color: var(--color-surface-4) !important;
    border: var(--color-surface-2) var(--default-border) !important;
}

[bg-variant = "tertiary"]:hover {
    background-color: var(--color-surface-0) !important;
}

[bg-variant = "error"] {
    transition: all var(--default-animation-duration) !important;
    background-color: var(--color-error) !important;
    color: var(--color-surface-0) !important;
    border: var(--color-surface-2) var(--default-border) !important;
}

[bg-variant = "error"]:hover {
    background-color: var(--color-error-hover) !important;
}

[bg-variant = "success"] {
    transition: all var(--default-animation-duration) !important;
    background-color: var(--color-success) !important;
    color: var(--color-surface-0) !important;
    border: var(--color-surface-2) var(--default-border) !important;
}

[bg-variant = "success"]:hover {
    background-color: var(--color-success-hover) !important;
}

[size-variant = "22"] {
    height: 22px;
}

[size-variant = "24"] {
    height: 24px;
}

[size-variant = "26"] {
    height: 26px;
}

[size-variant = "28"] {
    height: 28px;
}

[size-variant = "30"] {
    height: 30px;
}

[size-variant = "32"] {
    height: 32px;
}

[size-variant = "34"] {
    height: 34px;
}

[size-variant = "36"] {
    height: 36px;
}